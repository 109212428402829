/* rgba(51, 58, 86, 1) */

.arrow-container {
  position: relative;
  margin-inline: 10px;
  padding: 0;
  top: 00%;
  left: -0.125%;
  width: 60%;
  height: 100%;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}
.arrow,
.arrow-completed {
  width: 10%;
  min-height: 50px;
  max-height: 50px;
  background-color: rgba(51, 58, 86, 0.25);
  clip-path: circle();
}
.arrow-completed {
  background-color: rgba(51, 58, 86, 1);
}

@media screen and (max-width: 680px) {
  .arrow,
  .first-arrow,
  .last-arrow,
  .arrow-completed {
    height: 2vh;
    clip-path: circle();
  }
  /* .arrow-completed {
    display: inline-block;
    margin-top: 0px;
    width: 8.33%;
    border: 0px;
    border-color: white;
    background-color: rgba(51, 58, 86, 1);
    height: 2vh;
    clip-path: circle();
  }
*/
}
