.preq-letter {
  outline: 20px solid rgba(51, 58, 86, 1);
  outline-offset: -20px;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 1100px;
  width: 850px;
  transform: translate(-50%, -50%) scale(0.85);
  background-color: white;
  font-weight: 500;
}
.va-box,
.fha-box,
.rhs-box {
  outline: 2px solid rgb(22, 25, 36);
  outline-offset: -2px;
  display: inline-block;
  text-align: center;
  position: relative;
  padding: 1px;
  box-shadow: 0 1px 3px rgba(51, 58, 86, 1);
  margin: 5px;
  width: 250px;
  left: 10%;
}
.rhs-box {
  outline: 2px solid green;
}
.va-box {
  outline: 2px solid #0071bb;
}
.fha-box {
  outline: 2px solid #203b4e;
}

.preq-body {
  margin-top: 5%;
  position: absolute;
  text-align: left;
  top: 25%;
  left: 10%;
  width: 80%;
  font-size: 16px;
  font-variant: small-caps;
}
.HMBLogo {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  height: 125px;
}
.signature {
  height: 209px;
  position: absolute;
  top: 75%;
  left: 10%;
}
.equalhousing {
  position: absolute;
  top: 82.85%;
  left: 95%;
  width: 100px;
  transform: translateX(-100px);
}
.preq-date {
  position: absolute;
  top: 20%;
  left: 10%;
}
.qrCode-box {
  position: absolute;
  width: 25%;
  top: 75%;
  left: 65%;
}
.qrCode {
  size: 50px;
  position: relative;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.exit-button {
  position: absolute;
  left: 92%;
  width: 50px;
  font-size: 36px;
}
.download {
  left: 0;
  top: 0;
  transform: none;
  outline: none;
}

@media screen and (max-width: 680px) {
  .preq-letter {
    left: -45%;
    top: -20%;
    transform: translate(0, 0);
    transform: scale(0.5);
    /* -webkit-transform: scale(0.5); */
  }
  .overlay {
    width: 110vw;
  }
}
