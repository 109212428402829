.flex-container {
  display: flex;
}

.begin-button {
  position: absolute;
  background-color: rgba(83, 144, 217, 0.795);
  top: 35%;
  left: 50%;
  width: 15vw;
  height: 7.5vh;
  transform: translate(-50%, -50%);
  font-size: 2.5vh;
  border-radius: 0;
}
.flex-container > div {
  /* background-color: #f1f1f1; */
  margin: 3px;
  padding: 7px;
  /* font-size: 30px; */
}
