.selectedbutton {
  background-color: rgba(211, 211, 211, 0.5);
  color: rgba(2, 25, 99, 0.795);
}
.flex-container {
  display: flex;
  /* margin-left: 3rem; */
}

.flex-container > div {
  /* background-color: #f1f1f1; */
  margin: 10px;

  padding: 20px;
  /* font-size: 30px; */
}
