input {
  width: 400px;
  height: 55px;
  font-size: 28px;
  padding: 5px;
  margin: 5px;
  color: rgba(247, 245, 230, 1);
  background-color: rgba(51, 58, 86, .5);
}
input:focus {
  background-color: rgba(51, 58, 86, 1);
  color: rgba(247, 245, 230, 1);
}
