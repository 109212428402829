.maindisplay {
  width: 100vw;
  max-width: 1750px;
  height: auto;
  min-height: 1000px;
  font-family: sans-serif;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
      135deg,
      rgba(247, 245, 230, 0.75) 34%,
      rgba(82, 101, 143, 0.75) 100%
    ),
    url(../../../public/img/house.jpg);
  background-size: cover;
  background-position: top;
  display: grid;
  justify-items: center;
  justify-content: center;
  align-content: space-evenly;
}
/* .maindisplay2 {
  opacity: 0;
  padding: 10px;
  width: 1750px;
  height: 1000px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: sans-serif;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
      135deg,
      rgba(247, 245, 230, 0.75) 34%,
      rgba(82, 101, 143, 0.75) 100%
    ),
    url(../../../public/img/house.jpg);
  background-size: cover;
  background-position: top;
} */

@media screen and (max-width: 680px) {
  .maindisplay {
    width: unset;
    height: auto;
    min-height: 100vh;
  }
}
