.movebuttons {
  width: 25 vh;
  height: 10vh;
  /* margin-top: 2rem;
  margin-left: 3rem; */
}

.movebuttons-mobile {
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 75%;
  min-width: 800px;
  max-width: 1300px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.next {
  position: relative;
  top: 37.5%;
  left: 30%;
  color: #fff;
  font-size: 17px;
  animation-name: nextArrival;
}
.prev {
  position: relative;
  top: 37.5%;
  left: 20%;
  color: #fff;
  font-size: 17px;
}
.movebuttons-right {
  /* position: absolute;
  top: 40%;
  left: 90%; */
  background-image: linear-gradient(
    90deg,
    rgba(51, 58, 86, 0.85) 0%,
    rgba(82, 101, 143, 0.95) 80%
  );
  height: 125px;
  width: 125px;
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
  /* clip-path: polygon(
    0% 20%,
    60% 20%,
    60% 0%,
    100% 50%,
    60% 100%,
    60% 80%,
    0% 80%
  ); */
}
.movebuttons-right:hover {
  /* background-color: rgba(211, 211, 211, 0.5); */
  /* color: rgba(2, 25, 99, 0.795); */
  transform: translateY(-3px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.movebuttons-right:active {
  transform: translateY(1px);
  box-shadow: 0 5px 1px rgba(0, 0, 0, 0.2);
}
.movebuttons-left {
  /* position: absolute;
  top: 40%;
  left: 0%; */
  cursor: pointer;
  background-image: linear-gradient(
    90deg,
    rgba(51, 58, 86, 0.85) 0%,
    rgba(82, 101, 143, 0.95) 80%
  );

  height: 125px;
  width: 125px;
  clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
}
.movebuttons-left:hover {
  /* background-color: rgba(211, 211, 211, 0.5); */
  /* color: rgba(2, 25, 99, 0.795); */
  transform: translateY(-3px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.movebuttons-left:active {
  transform: translateY(1px);
  box-shadow: 0 5px 1px rgba(0, 0, 0, 0.2);
}
.chevron-next,
.chevron-prev {
  opacity: 0;
}

@media screen and (max-width: 680px) {
  .movebuttons-right {
    position: relative;
    display: inline-block;
    top: 0.75%;
    left: 0%;
    height: 15vw;
    width: 15vw;
    clip-path: circle();
  }
  .movebuttons-left {
    position: relative;
    display: inline-block;
    top: 0.75%;
    height: 15vw;
    width: 15vw;
    clip-path: circle();
  }
  /* .movebuttons-left:active,
  .movebuttons-left:hover,
  .movebuttons-right:active,
  .movebuttons-right:hover {
    transform: none;
  } */
  .next,
  .prev {
    opacity: 0;
  }
  .chevron-next {
    opacity: 100;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    height: 5vh;
    filter: invert();
  }
  .chevron-prev {
    opacity: 100;
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%) rotate(180deg);
    height: 5vh;
    filter: invert();
  }

  .movebuttons-mobile {
    min-width: unset;
    min-height: unset;
    justify-content: center;
    /* position: unset; */
    /* display: flex; */
    width: 100vw;
  }
}
