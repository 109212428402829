.nameLastName {
  width: 100%;
  margin-top: 25px;
  margin-left: 2rem;
  margin-block-end: 0rem;
  font-size: 36px;
  font-weight: 700;
  font-variant: small-caps;
}
.edit-button {
  background-color: rgba(82, 101, 143, 0);
  color: rgba(51, 58, 86, 1);
  text-shadow: 1px 1px black;
  border-radius: 0px;
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  margin: 1px;
  padding: 6px;
  font-size: 28px;
}
#phoneEmail {
  width: 100%;
  line-height: 1;
  font-size: 24px;
  margin-left: 10px;
}

@media screen and (max-width: 680px) {
  .nameLastName {
    width: auto;
    max-width: 95vw;
    left: 0;
    margin-top: 2vh;
    margin-left: 2vh;
    margin-bottom: 0;
    text-align: center;
    font-size: 1rem;
  }
  .edit-button .modify-info {
    top: 100%;
    width: 5vw;
    font-size: 1.25rem;
  }
  .modify-info {
    position: unset;
    transform: rotate(30deg);
    font-size: 1.15rem;
  }
  .delete {
    position: unset;
    font-size: 1.25rem;
  }
  #phoneEmail {
    font-size: 0.85rem;
    width: 75vw;
  }
  .modify-info-job,
  .delete-job {
    position: relative;
    font-size: 0.75rem;
    display: inline;
  }
}
