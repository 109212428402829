/* 
#F7F5E6
rgba(247, 245, 230, 1)
#333A56
rgba(51, 58, 86, 1)
#52658F
rgba(82, 101, 143, 1)
#E8E8E8 
rgba(232, 232, 232, 1)
*/
.final-container {
  width: auto;
  z-index: 10;
}

.card {
  outline: 2px solid rgba(51, 58, 86, 1);
  outline-offset: -2px;
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
  width: 100%;
  max-width: 1000px;
  min-height: 7.5vh;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  margin: 13px;
  padding: 3vw;
  animation-name: cardMoveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

.bottom-disclaimer {
  color: red;
  font-size: 25px;
  text-align: justify;
}
.final-card {
  text-align: center;
  display: inline-block;
  position: relative;
  height: 275px;
  width: 750px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  margin: 13px;
  padding: 10px;
  background-color: rgba(232, 232, 232, 0.5);
}

select {
  font-size: 36px;
  font-weight: 500;
}
.edit-form {
  margin: 0;
  padding: 0;
  display: inline-block;
}
.policy-exit-button{
  clip-path: none;
  font-size: 24px;
}
.overlay {
  position: absolute;
  display: grid;
  top: 50%;
  left: 50%;
  width: 95vw;
  height: 95vh;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(3px);
  z-index: 10;
}
.modal {
  /* clear: all; */
  position: absolute;
  display: grid;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  height: 750px;
  padding: 50px;
  background-color: rgba(247, 245, 230, 1);
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  font-size: 35px;
  z-index: 10;
}

button {
  text-align: center;
  font-size: 32px;
  color: rgba(247, 245, 230, 1);
  background-color: rgba(51, 58, 86, 1);
  clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
  cursor: pointer;
  border: none;
  padding: 8px;
  margin: 5px;
  min-width: 20vw;
  font-variant: small-caps;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  animation-name: buttonMoveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.edit-button:hover,
button:hover {
  /* background-color: rgba(211, 211, 211, 0.5); */
  /* color: rgba(2, 25, 99, 0.795); */
  cursor: pointer;
  transform: scale(1.05) translateY(-3px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
@keyframes buttonMoveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  70% {
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes cardMoveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-5%);
  }
  70% {
    transform: translateX(1%);
  }

  100% {
    opacity: 1;
    transform: translate(0%);
  }
}
@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(-45%);
  }
  70% {
    transform: translateX(-51%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%);
  }
}

@keyframes modalShakeInPlace {
  0% {
    transform: translate(-47%, -47%);
  }
  50% {
    transform: translate(-53%, -53%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.edit-button:active,
.button:active {
  cursor: pointer;
  transform: translateY(1px);
  box-shadow: 0 5px 1px rgba(0, 0, 0, 0.2);
}

.button-box {
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.footer {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 250px;
  margin-top: 15px;
  padding-top: 10px;

  background-image: linear-gradient(
    to right bottom,
    #777,
    rgba(0, 0, 0, 0.682)
  );
}
.purpose-box,
.VA-box,
.marital-status-box {
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  border: 0;
  margin: 0;
  padding: 0;
}
.question-text {
  color: rgba(82, 101, 143, 0.75);
  text-shadow: 1px 1px #777;
  position: relative;
  text-align: center;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  font-size: 36px;
  font-weight: 700;
  animation-name: moveInRight;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.Comprar.selectedbutton,
.Vender.selectedbutton,
.Refinanciar.selectedbutton {
  color: rgba(#52658fc6);
  outline: 3px solid rgba(82, 101, 143, 1);
  background-image: linear-gradient(
    135deg,
    rgba(232, 232, 232, 0.25) 0%,
    rgba(232, 232, 232, 0.5) 50%
  );
}
.Comprar,
.Buy,
.Refinance,
.Married,
.Unmarried,
.Yes,
.Vender,
.Refinanciar,
.Casada,
.Si,
.No {
  width: 500px;
  height: 100px;
  clip-path: polygon(7.5% 0%, 100% 0%, 92.5% 100%, 0% 100%);
  margin: 0;
  padding: 0;
  border-radius: 0;
  font-weight: 700;
  display: inline-block;
  font-size: 24px;
  margin-bottom: 1vh;
}

.Comprar {
  background-color: #ffffff00;
  background-image: linear-gradient(
    135deg,
    rgba(51, 58, 86, 0.85) 0%,
    rgba(82, 101, 143, 0.95) 80%
  );
  /* url("../../../public/img/compra.jpeg"); */
  background-size: cover;
  background-position: center;
}
.Refinanciar {
  background-color: #ffffff00;
  background-image: linear-gradient(
    135deg,
    rgba(51, 58, 86, 0.85) 0%,
    rgba(82, 101, 143, 0.95) 80%
  );
  /* url("../../../public/img/refi.jpeg"); */
  background-size: cover;
  background-position: center;
}
.Vender {
  background-color: #ffffff00;
  background-image: linear-gradient(
    135deg,
    rgba(51, 58, 86, 0.85) 0%,
    rgba(82, 101, 143, 0.95) 80%
  );
  /* url("../../../public/img/venta.jpeg"); */
  background-size: cover;
  background-position: center;
}

header {
  position: relative;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  max-width: 1750px;
  max-height: 5vh;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  padding: 10px;
  background-image: linear-gradient(
    135deg,
    rgba(51, 58, 86, 1) 0%,
    rgba(82, 101, 143, 1) 80%
  );
}
.disclaimer-box {
  padding-inline: 1.5vw;
  width: auto;
  max-width: 90vw;
  font-size: 26px;
  font-weight: 700;
  text-align: justify;
}
.first-client-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 0.5px 0.5px;
}

/* .marital-status-box {
  display: grid;
  justify-content: center;
  align-content: center;
  font-size: 16px;
} */
.add-spouse-button {
  margin: 0;
  padding: 0;
  border-radius: 0;
  font-weight: 700;
  display: inline-block;
  font-size: 1.25rem;
  width: 250px;
  height: 75px;
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}
/* .VA-box {
  display: grid;
  justify-content: center;
  align-content: center;
  font-size: 16px;
  height: 200px;
  width: 500px;
} */
.coborrower-box {
  font-size: 16px;
  position: absolute;
  height: 500px;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-debt-checkbox {
  position: relative;
  top: 7.5%;
  /* left: 2.5%; */
  height: 30px;
  width: 30px;
  outline-offset: -2.5px;
}

.no-debt-checkbox-label {
  background-color: rgba(51, 58, 86, 0.75);
  font-size: 1.85rem;
  font-weight: 700;
  text-align: center;
  min-width: 85%;
  max-width: 95%;
  height: 50px;
  font-variant: small-caps;
  color: rgba(247, 245, 230, 0.85);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  cursor: pointer;
}
.add-client-button {
  margin: 0;
  padding: 0;
  position: relative;
  top: 10%;
  border-radius: 0;
  font-weight: 700;
  display: inline-block;
  font-size: 1.25rem;
  width: 100%;
  height: 75px;
  clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
}

.add-debt-button,
.job-info-button {
  border-radius: 0;
  clip-path: none;
  padding: 0px;
  margin: 0px;
  min-width: 100%;
  min-height: 50px;
  padding: 1vw;
}
.job-info-button2 {
  width: 100%;
  font-size: 25px;
  position: relative;
  clip-path: none;
  padding: 25px;
  margin: 10px;
  border-radius: 1000px;
}
.job-info {
  font-variant: small-caps;
  font-weight: 700;
  font-size: 28px;
  color: black;
}

.form-box {
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  width: auto;
  height: auto;
  padding: 100px;
  align-content: space-evenly;
}
.will-call {
  color: rgba(82, 101, 143, 0.95);
  font-size: 1.75rem;
  text-align: justify;
  text-shadow: 1px 1px black;
  width: 100%;
  margin-bottom: 2vh;
}
.submit-buttons {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.final-header {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  font-variant: small-caps;
}
.final-box {
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.final-submit-button {
  width: 90%;
  left: 50%;
  margin: 0;
  /* position: absolute; */
  top: 12.5%;
}
.job-info-box,
.client-button-box,
.debt-box,
.credit-box {
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  min-width: 50vw;
  max-width: 1200px;
}
.bottom-warning {
  font-size: 26px;
  font-weight: 500;
  color: red;
}
.disclaimer-checkboxes {
  cursor: pointer;
}
.disclaimer-checkboxes input {
  position: relative;
  padding: 0;
  margin-inline-end: 1vw;
  height: 20px;
  width: 20px;
}
.title {
  margin: 5px;
  margin-left: 15px;
  color: rgba(247, 245, 230, 1);
  font-size: 2.5rem;
  font-variant: small-caps;
  text-shadow: black;
}
.review-box * {
  left: 0;
  margin: 0;
  padding: 0;
}
.new-prequal {
  clip-path: none;
  width: 100vw;
  height: 5vh;
  border-radius: 0;
  font-weight: 700;
  display: inline-block;
  font-size: 1rem;
  color: rgba(247, 245, 230, 1);
  background-color: rgba(51, 58, 86, 1);
  text-align: center;
  font-variant: small-caps;
  box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
  animation-name: buttonMoveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  text-decoration: none;
}
.review-box {
  background-image: linear-gradient(
      135deg,
      rgba(247, 245, 230, 0.75) 34%,
      rgba(82, 101, 143, 0.75) 100%
    ),
    url(../../../public/img/house.jpg);
  background-size: cover;
  background-position: top;
  width: 100vw;
  margin: 0;
  padding: 0;
  display: grid;
  align-items: center;
  justify-items: center;
}
.review-box .final-card {
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
  margin-bottom: 1vh;
}
.review-box .final-card * {
  margin: 0.1vh;
}
.review-box .nameLastName {
  font-size: 1.75rem;
  width: 100%;
}
.review-box .final-header,
.review-box #phoneEmail {
  font-size: 1.25rem;
}
.NMLSID {
  text-shadow: 0 3px 2px rgba(0, 0, 0, 0.15);
  font-size: 1.5rem;
  width: 10vw;
  color: rgba(247, 245, 230, 0.5);
}
.locale-span {
  color: rgba(247, 245, 230, 0.85);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin-inline: 1vw;
}
.locale {
  cursor: pointer;
  margin: 0.25vh;
  height: 2vh;
}
.locale-box {
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
  justify-content: center;
  align-items: flex-start;
  justify-items: center;
}
@media screen and (max-width: 680px) {
  .card {
    min-width: 80vw;
    max-width: 90vw;
  }
  #root {
    justify-content: center;
    align-items: center;
    justify-items: stretch;
  }
  .policy-exit-button{
    clip-path: none;
    font-size: 12px;
    text-align: center;
    width:100vw;
    height: 2.5vh;
  }
  button,
  .Buy,
  .Refinance,
  .Married,
  .Unmarried,
  .Yes,
  .Comprar,
  .Vender,
  .Refinanciar,
  .Casada,
  .Si,
  .No {
    margin-bottom: 1vw;
    padding: 0;
    border-radius: 0;
    font-weight: 700;
    display: inline-block;
    font-size: 0.75rem;
    width: 65vw;
    height: 8vh;
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  }
  .NMLSID {
    width: auto;
    font-size: 0.75rem;
    margin: unset;
  }

  .question-text {
    font-size: 1.5rem;
  }
  .purpose-box,
  .marital-status-box,
  .VA-box {
    display: grid;
    align-items: center;
    justify-items: center;
    width: auto;
    height: auto;
    transform: none;
    position: unset;
  }
  .disclaimer-box,
  .disclaimer-checkboxes {
    position: unset;
    justify-items: center;
    display: grid;
    width: auto;
    height: auto;
    font-size: 0.85rem;
    margin-bottom: 2vh;
    max-width: 95vw;
  }
  .disclaimer-checkbox-container {
    text-align: left;
    margin-top: 1vh;
  }
  .disclaimer-box {
    text-align: justify;
  }
  .disclaimer-checkboxes input {
    /* position: absolute; */
    display: inline-block;
    transform: translateY(20%);
    margin-right: 1vw;
    width: 4vw;
  }

  header {
    width: 100vw;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 1.25rem;
    text-align: center;
  }
  .modal {
    padding: 0;
    width: 85vw;
    justify-content: center;
    height: auto;
  }
  .form-box {
    align-content: center;
    justify-content: center;
    position: unset;
    display: grid;
    width: auto;
    height: auto;
    transform: none;
  }
  .form-box input {
    margin: 0;
    width: auto;
    font-size: 1rem;
    text-align: center;
  }
  .job-info-box,
  .client-button-box,
  .debt-box,
  .credit-box,
  .final-box {
    display: grid;
    transform: none;
    top: 10%;
    left: 5%;
    height: auto;
    width: auto;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
  .final-box {
    height: auto;
  }
  .add-spouse-button {
    margin-bottom: 1vh;
    position: unset;
    clip-path: none;
    width: 100vw;
    left: 0;
    top: 50%;
    /* transform: translate(-50%, -50%); */
  }
  .credit-box,
  .final-box {
    position: unset;
    margin-top: 7.5vh;
    margin-bottom: 2.5vh;
  }
  .footer {
    width: 100vw;
    height: 15vh;
    background-image: linear-gradient(to right bottom, #777, rgba(0, 0, 0, 1));
  }
  .add-client-button {
    clip-path: none;
    height: 5vh;
  }
  .no-debt-checkbox-label {
    padding: 1vh;
    height: auto;
    min-width: 50vw;
    max-width: 90vw;
    font-size: 0.75rem;
  }
  .no-debt-checkbox-label input {
    height: 2vh;
    width: 2vh;
  }
  select {
    text-align: center;
    width: 40vw;
    margin-bottom: 1vh;
    margin-top: 1vh;
    font-size: 1.15rem;
  }
  .job,
  .job input,
  .debt,
  .debt input {
    position: unset;
    font-size: 1.25rem;
  }
  .job-info {
    font-size: 1rem;
  }
  .job input,
  .debt input {
    height: 5vh;
  }
  .debt input {
    margin: 1vh;
  }
  .bottom-warning {
    margin-top: 1vh;
    font-size: 0.75rem;
  }
  .add-debt-button,
  .job-info-button {
    width: 100%;
    height: auto;
    font-size: 0.75rem;
    padding: 1vh;
    border-radius: 1000px;
    margin-bottom: 1vh;
    margin-top: 1vh;
  }
  .job-info-button2 {
    font-size: 0.75rem;
    top: 0;
    left: -5%;
    clip-path: none;
    padding: 10px;
    width: auto;
    min-width: 80%;
    max-width: 100%;
    height: 2rem;
  }

  .credit-box .question-text {
    font-size: 1rem;
  }
  .credit-box input {
    width: 25vw;
    height: 3.5vh;
    font-size: 1rem;
  }
  .credit-box .bottom-disclaimer {
    font-size: 1rem;
  }

  .credit-score-card {
    height: 22vh;
  }
  .final-card {
    display: block;
    height: auto;
    position: unset;
    margin-top: 25px;
    width: auto;
    animation-name: cardMoveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }
  .final-header {
    font-size: 1rem;
  }
  .final-box .modify-info {
    top: 23.5%;
    left: 15%;
    font-size: 1.2rem;
  }
  .final-box .delete {
    top: 21.5%;
    left: 75%;
    font-size: 1.5rem;
  }
  .final-submit-button {
    clip-path: none;
    border-radius: 0;
    height: 5vh;
    width: 90vw;
    position: unset;
    margin-top: 1vw;
    left: 0;
    border-radius: 1000px;
  }
  .review-box {
    justify-items: flex-start;
  }
  @keyframes cardMoveInLeft {
    0% {
      opacity: 0;
      transform: translateX(5%);
    }
    70% {
      transform: translateX(-5%);
    }

    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
  .will-call {
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: 80vw;
    font-size: 1.25rem;
  }
  .bottom-disclaimer {
    font-size: 1rem;
    width: 80vw;
    text-align: justify;
  }
}
